







































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component({})
export default class StatusDetails extends Vue {
  private isLoading = false
  private data = null
  private dialogItem = null
  private dialogVisible = false

  private get id() {
    return this.$route.params.id || ''
  }

  private get lastMessageClass() {
    return this.data?.last?.status === 'SUCCESS'
      ? 'last-message last-message-success'
      : 'last-message last-message-error'
  }

  private get lastMessage() {
    return this.data?.last?.message || (this.data?.last?.status === 'SUCCESS' ? 'Successfully transferred' : '-')
  }

  private get canSeeInternals() {
    return this.data?.canSeeInternals
  }

  private get limitMessage() {
    const limit = this.data ? this.data.limit : 0
    if (!limit || !this.data || !this.data.logs) {
      return ''
    }
    if (limit > this.data.logs.length) {
      return ''
    }
    const msg = this.$t('Showing max %s items')
    return msg.replace('%s', '' + limit)
  }

  private showDialog(item) {
    this.dialogItem = item
    this.dialogVisible = true
  }

  private mounted() {
    this.load()
  }

  private load() {
    if (!this.id) {
      return
    }
    this.isLoading = true
    this.$axios
      .get('/v4/site/integrations/accounting/status/item/' + this.id)
      .then((response) => {
        console.log('reps:', response.data.data)
        this.data = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error fetching status items')
        this.isLoading = false
      })
  }

  private getShortMessage(item): string {
    const message = item?.message || ''
    if (!message) {
      return ''
    }
    let firstLine = message.split('\n')[0]
    if (firstLine.length > 250) {
      firstLine = firstLine.substr(0, 250) + '...'
    }
    return firstLine
  }

  private formatRequestResponse(data): string {
    if (!data) {
      return '(No data)'
    }
    if (data.substr(0, 1) === '{' || data.substr(0, 1) === '[') {
      const parsed = JSON.parse(data)
      if (parsed) {
        return JSON.stringify(parsed, null, 2)
      }
    }
    return data
  }
}
